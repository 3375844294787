@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.insurance {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .insurance:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.insurance .insurance-content {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 5rem;
}

.insurance h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-align: center;
    padding: .5rem 0;
}

.insurance p {
    color: rgb(31, 29, 29);
    text-align: center;
}

.insurance-caption {
    width: 40%;
    margin: auto;
    padding: 0 1rem;
}

.family {
    width: 700px;
    margin: auto;
    padding: 1rem;
    top: 30%;
}

.family img {
    width: 100%;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.insurance h2 {
    color: white;
    font-size: 1.5rem;
}

.insurance h1 {
    font-size: 2rem;
}
.insurance .insurance-content {
    flex-wrap: wrap;
}

.family {
    width: 90%;
}

.insurance-caption {
    width: 100%;
}
}