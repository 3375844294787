@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.heroin {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.575);
    position: relative;
  }
  
  .heroin:before {
    content: '';
    position: absolute;
    background: url('https://rodeo-recovery-substance-abuse.s3.us-west-1.amazonaws.com/heroin.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.heroin {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.heroin .heroin-content {
    display: block;
    position: absolute;
    top: 50%;
    padding: 1rem;
}

.heroin h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.heroin h3 {
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.heroin h1 {
    font-size: 2rem;
}

.heroin h3 {
    font-size: 1rem;
}
}