@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');

.hero {
  width: 100%;
  height: 100vh; /* Standard viewport height */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(0, 0, 0, 0.479);
}

.hero:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero-bg.webp') no-repeat right center/cover; /* Align background to the right */
  height: 100%;
  width: 100%;
  z-index: -1;
}

.hero .hero-container {
  width: 100%;
}

.hero .content {
  width: 60%;
  bottom: 30%;
  position: absolute;
  padding: 1rem;
  text-align: center;
  animation: fadeIn 2s ease-in-out; /* Apply fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start slightly lower */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

.hero h1 {
  font-size: 1.6rem;
  color: rgb(255, 255, 255);
  font-family: 'roboto', serif;
  padding: .5rem 0;
  font-weight: normal;
}

.hero h2 {
  font-size: 6rem;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  font-family: 'Kuta', serif;
}

.hero p {
  color: rgb(228, 221, 221);
  line-height: normal;
}

.heroBtn {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.heroBtn button {
  background-color: #ffffff;
  color: rgb(1, 37, 19);
  border: none;
  border-radius: 50px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 1rem 3rem;
}


/* MEDIA QUERIES */

@media screen and (max-width: 940px) {
  .hero .content {
    width: 100%;
    left: 0;
  }

  .hero h1 {
    font-size: 1.8rem;
  }

  .hero h2 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 600px) {
  .hero h1 {
    font-size: 1rem;
  }

  .hero h2 {
    font-size: 4rem;
  }

  .hero p {
    font-size: 14px;
  }

  .heroBtn button {
    padding: .5rem 1rem;
    font-size: 14px;
  }
  



}
