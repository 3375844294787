@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.detox-benzodiazepines {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .detox-benzodiazepines:before {
    content: '';
    position: absolute;
    background: url('../../assets/detox-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.detox-benzodiazepines {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.detox-benzodiazepines .detox-benzodiazepines-content {
    display: block;
    position: absolute;
    top: 60%;
    right: 5%;
    padding: 1rem;
}

.detox-benzodiazepines h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-transform: uppercase;
    text-align: center;
}

.detox-benzodiazepines h2 {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-align: left;
}


/* MEDIA QUERIES */


@media screen and (max-width:600px) {

    .detox-benzodiazepines h1 {
        font-size: 2rem;
        text-align: center;
    }
    

    .detox-benzodiazepines .detox-benzodiazepines-content {
        right: 0%;
        top: 70%;
    }

}
