@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.detox {
  width: 100%;
  height: 70vh;
  position: relative;
}

.detox:before {
  content: '';
  position: absolute;
  background: url('../../assets/method-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}
  
  .detox-content {
    top: 50%;
    left: 5%;
    position: absolute;
}


.detox-content h1{
  text-align: center;
  color: var(--primary-color);
  font-size: 3rem;
}

/* MEDIA QUERIES - HERO */

@media screen and (max-width:1200px) {

.detox h1 {
    font-size: 2rem;
    text-align: center;
}

.detox-content {
  width: 100%;
  top: 92%;
  left: 0%;
  position: absolute;
}




}