@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.benzodiazepines {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .benzodiazepines:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa-stimulant.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.benzodiazepines {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.benzodiazepines .benzodiazepines-content {
    width: 50%;
    display: block;
    position: absolute;
    top: 35%;
    left: 5%;
    padding: 1rem;
}

.benzodiazepines h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}

.benzodiazepines h3 {
    color: var(--primary-color);
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.benzodiazepines h1 {
    font-size: 2rem;
}

.benzodiazepines h3 {
    font-size: 1rem;
}

.benzodiazepines .benzodiazepines-content {
    width: 60%;
}

}

@media screen and (max-width:600px) {
    .benzodiazepines .benzodiazepines-content {
        width: 100%;
        top: 70%;
        left: 5%;
    }
}