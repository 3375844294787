@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: #1b3a18;
}



.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.logo-footer {
    width: 120px;
  }
  
  .logo-footer img {
    margin-left: 1rem;
    width: 100%;
  }
  

.footer .top .footer-icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}

.footer h1 {
    font-size: 2rem;
    color: white;
}

.footer h2 {
    font-size: 2rem;
}

.footer h3 {
    color: white;
    font-size: 1rem;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.col {
    text-align: center;
    width: 30%;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    color: rgb(243, 180, 6);
}


.col p {
    font-family: 'Montserrat', sans-serif;
    margin: .5rem 0;
    color: white;
    font-weight: 500;
    text-align: center;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus {
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-dark);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    top: 3.8rem;
    color: #aaa;
    cursor: pointer;
}

.footer form .social-icon {
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}


.col a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}


.certification {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.certificationLeft {
    width: 40%;
    text-align: center;
    margin: auto;

}

.certificationRight {
    width: 40%;
    padding: 1rem;
    margin: auto;
}


.certification h3 {
    text-align: center;
    font-size: .8rem;
}

.certification p {
    text-align: center;
    color: white;
}

.certificationLeftImage {
    width: 200px;
    margin: auto;
}

.certificationLeftImage img{
    width: 100%;
}

.certificationRightImage {
    width: 300px;
    margin: auto;
}

.certificationRightImage img{
    width: 100%;
}



.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}


@media screen and (max-width: 940px) {
    .certification {
        flex-wrap: wrap;
    }

    .certificationRight {
        width: 100%;
    }

    .certificationLeft {
        width: 100%;
    }
    
}

.copyright {
    padding-top: 1rem;
}

.copyright p {
    color: white;
    text-transform: uppercase;
}

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

    .footer .col-container {
        grid-template-columns: 3fr 3fr 3fr ;
    }

    .footer form {
        grid-column: 1 / span 4;
        text-align: center;
    }

    .footer h1, .footer h2, .footer h3, .footer p {
        text-align: center;
    }
    
}



@media screen and (max-width: 600px) {
    .col {
        width: 100%;
    }

}