@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.detox-adderall {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .detox-adderall:before {
    content: '';
    position: absolute;
    background: url('../../assets/detox-adderall-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.detox-adderall {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.detox-adderall .detox-adderall-content {
    display: block;
    position: absolute;
    top: 60%;
    right: 5%;
    padding: 1rem;
}

.detox-adderall h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(241, 235, 235);
    text-transform: uppercase;
    text-align: center;
}

.detox-adderall h2 {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(241, 235, 235);
    text-align: left;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {



}

@media screen and (max-width:600px) {

    .detox-adderall h1 {
        font-size: 2rem;
        text-align: center;
    }
    
    .detox-adderall .detox-adderall-content {
        right: 0%;
        top: 70%;
    }

}
