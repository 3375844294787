
.section1 {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .section1:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.08;
  }
  
.section1-header {
    text-align: center;
    padding: 4rem 1rem 1rem 1rem;
}



.section1-header h1 {    
    font-family: "Wildest";
    font-size: 2rem;
}

.section1-header h2 {
    font-size: 1rem;
    color: var(--primary-color);
    font-family: "Wildest";
    padding: .5rem 0;
    text-transform: uppercase;
}

.section1-header p {
    text-align: justify;
    padding: .5rem 0;
}

.section1-content {
    width: 100%;
    margin: auto;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    padding: 4rem 0 0 0;
}

.section1-left {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.section1-left h1 {
    font-family: "Wildest";
    font-size: 2rem;
}

.section1-left ul li {
    padding: .5rem 0;
    font-size: 15px;
    text-align: justify;
}

.section1-right {
    width: 600px;
    padding: 1rem;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px -5px rgb(9, 127, 182);
    -moz-box-shadow: 0px 0px 10px -5px rgb(9, 127, 182);
    box-shadow: 0px 0px 10px -5px rgb(9, 127, 182);
}

.section1-right img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.section1 p {
    color: #312f2f;
    text-align: justify;
}




@media  screen and (max-width: 940px) {

    .section1-header h1 {    
        font-size: 1.5rem;
    }

    .section1-left h1 {
        font-size: 1.5rem;
    }

    .section1-left h1 {
        text-align: center;
    } 

    .section1-content {
        flex-wrap: wrap;
    }

    .section1-left {
        width: 100%;
    }

    .section1-right {
        width: 90%;
    }


}