@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.jobs-section {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .jobs-section:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.jobs-section-content {
    padding: 1rem;
}

.jobs-header {
    padding: 3rem 0;
}

.jobs-header h1 {
    font-size: 1.2rem;
}
.jobs-header p {
    padding: .5rem 0;
    text-align: justify;
}

.jobs-section-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.jobs-left {
    width: 400px;
    margin: auto;
}

.jobs-left img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.jobs-right {
    width: 50%;
    margin: auto;
}


.jobs-right h2 {
    font-size: 1.5rem;
    color: #1aa5db;
}

.jobs-right h1 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 1rem;
    padding: .5rem 0;
}

.jobs-right p {
    line-height: 25px;
    text-align: justify;
}

.job-link a {
    color: blue;
}

.job-link a:hover {
    color: rgb(226, 114, 9);
}





@media screen and (max-width:940px) {
    .jobs-section-flex {
        flex-wrap: wrap-reverse;
        gap: 1rem;
    }

    .jobs-left {
        width: 100vw;
    }

    .jobs-right {
        width: 100vw;
    }

}

