
.about-section {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .about-section:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.about-section-content {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 3rem 1rem;
}


.about-section-left {
    width: 50%;
    margin: auto;
}

.about-section-right {
    width: 500px;
    margin: auto;
}

.about-section-right img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.about-section-left p {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    color: rgb(0, 0, 0);
    margin: .5rem 0;
    font-size: 14px;
    line-height: 20px;
}

.about-section-left h1 {
    padding: .5rem 0;
    font-size: 1.5rem;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}

.about-btn button {
    background-color: var(--primary-color);
    border-radius: 5px;
    border: none;
}

.about-btn button:hover {

    background-color: black;
    color: white;
}


@media screen and (max-width: 940px) {

    .about-section-content {
        flex-wrap: wrap;
    }

    .about-section-left {
        width: 100%;
        margin: auto;
    }
    
    .about-section-right {
        width: 100%;
    }


}






