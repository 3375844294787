@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');



.detox-section {
    width: 100%;
    height: auto;
    position: relative;
}
  
  .detox-section:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }



  .detox-section-header {
    text-align: left;
    padding: 1rem;
  }
  
  .detox-section-header h1 {
    padding: 1rem 0;
  }
  
  .detox-section-header p {
    text-align: left;
  }
  
  
  .detox-section-left h5 {
    padding: 1rem 0;
    color: rgb(201, 140, 8);
  }
  
  .detox-section-right2 h5 {
    padding: 1rem 0;
    color: rgb(201, 140, 8);
  }



  .detox-section-content {
    padding: 3rem 0;
  }

.detox-section-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}


.detox-section-right {
    width: 50%;
    padding: 1rem;
    margin: auto;
}

.detox-section-right img {
    width: 100%;
    border-radius: 10px;
}

.detox-section-left {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.detox-section-left p {
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    padding: .5rem 0;
}

.detox-section-left h1 {
    font-family: 'Poppins', sans-serif;
    padding: .5rem 0;
}

/* FLEX 2 */


.detox-section-flex2 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}


.detox-section-left2 {
    width: 50%;
    padding: 1rem;
    margin: auto;
}

.detox-section-left2 img {
    width: 100%;
    border-radius: 10px;
}

.detox-section-right2 {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.detox-section-left2 p {
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    padding: .5rem 0;
}

.detox-section-left2 h1 {
    font-family: 'Poppins', sans-serif;
    color: black;
    padding: .5rem 0;
}





@media  screen and (max-width:940px) {

    .detox-section-flex {
        flex-wrap: wrap;
    }
    
    
    .detox-section-left {
        width: 100%;
    }

    .detox-section-right {
        width: 90%;
    }


    .detox-section-flex2 {
        flex-wrap: wrap-reverse;
    }
    
    
    .detox-section-left2 {
        width: 90%;
    }

    .detox-section-right2 {
        width: 100%;
    }


}