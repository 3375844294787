@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
    background: #063302;
    padding: .5rem 0;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-logo {
  width: 80px;
  padding: 1rem;
  z-index: 2;
}

.navbar-logo img {
  width: 100%;
  display: block;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #084702;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
}

.navbar li {
    width: max-content;
    font-family: 'roboto', sans-serif;
    padding: .3rem 0;
}

.navbar li a {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  padding: 1rem;

}

  .menu-item .sub__menus li a {
    color: #f5eeee;
    font-size: 14px;
    padding: .5rem;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }


  .navbar .hide {
    display: none;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #063302;
    border: none;
    margin-top: 5px;
    z-index: 1000;
    width: 240px;
    border-radius: 5px;
  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    top: 10px;
    right: 1px;

  }

  /* SUBMENU */

  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }

  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }

  
@media screen and (max-width: 1100px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }
    
    .navbar {
      background: rgb(255, 255, 255);
      padding: 0;
  }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 80%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        transition: 0.5s ease-in;
        background-color: rgb(255, 255, 255);
    }

    .menu-item .sub__menus {
      background-color: rgb(6, 92, 63);
      width: 100%;
      left: 0%;
    }

    .menu-item .sub__menus ul li{
      width: 100%;
      left: 0;
      text-align: center;
    }
  
  
    .menu-item .sub__menus li a:hover {
      color: rgb(255, 123, 0) !important;
    }
  

    .navbar li a {
      color: var(--primary-color);
      font-size: 1rem;
      padding: 1rem 1.5rem;
  
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
        width: 100%;
        padding: .3rem 3rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.185);
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
        text-align: left;
      }

      li .submenu-header {
        font-size: 14px;
      }

      .navbar .navbar-container {
        justify-content: space-between;
      }

      .navbar-logo {
        width: 100px;
        padding: .5rem;
      }

      .navbar .hide {
        display: block;
      }
    
      .navbar .show {
        display: none;
      }

}





