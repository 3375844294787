@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.about {
    width: 100%;
    height: 70vh;
    position: relative;
    background: rgba(0, 0, 0, 0);
  }
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-about.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.about .about-content {
    width: fit-content;
    position: absolute;
    bottom: 30%;
    right: 20%;
    padding: 1rem;
}

.about h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.about .about-content {
    width: 100%;
    right: 0%;
}

.about h1 {
    font-size: 3rem;
    text-align: center;
}
}