@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
:root {
  --primary-color: #063302;
  --primary-dark: #be832a;
  --secondary-color: #46a828;

}

span {
  color: var(--secondary-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: rgb(4, 111, 252);
}

p {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 25px;
  font-family: "Wildest";
  color: var(--primary-color);

}

h2 {
  font-size: 25px;
  font-family: "Wildest";
  color: var(--primary-color);
}

.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

button {
  font-family: 'Roboto', sans-serif;
  padding: .5rem 2rem;
  margin: 1rem 0;
  background-color: rgba(5, 71, 5, 0.699);
  border: 1px solid rgba(5, 71, 5, 0.699);
  color: #f8f8f8;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 16px;
  cursor:pointer;

}

button:hover {
  color: black;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.678);
  border: 1px solid rgba(255, 255, 255, 0.678);
}

span {
  color: var(--primary-color);
}




  /* MEDIA QUERIES */
  
  @media screen and (max-width:940px) {

    p {
        font-size: 14px;
        text-align: center;
    }
}

