@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .contact:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.contact .contact-content {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 5rem 0 0 0;
}

.contact h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-align: center;
}


.contact-caption {
    width: 40%;
    margin: auto;
    padding: 0 1rem;
}

.team {
    width: 700px;
    margin: auto;
    padding: 1rem;
    top: 30%;
}

.team img {
    width: 100%;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.contact h2 {
    color: white;
    font-size: 1.5rem;
}

.contact h1 {
    font-size: 2rem;
}
.contact .contact-content {
    flex-wrap: wrap;
}

.contact {
    width: 100%;
}

.contact-caption {
    width: 100%;
}
}