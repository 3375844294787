@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.section-bg {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.section-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.05;
}

#cards {
  padding: 3rem 0;
}

.main-cards-header {
  padding: 1rem;
}

.main-cards-header h1 {
  text-align: center;
  font-size: 2rem;
  color:var(--primary-color);
}

.main-cards-header p {
  padding: 1rem 0;
  color: rgb(17, 17, 17);
  text-align: center;
}




/* CARDS */

.cards-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.services-card {
  width: 25%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.100);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
}

.services-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.services-card h1 {
  font-size: 1.5rem;
  color: rgb(61, 58, 58);
  text-align: center;
  padding: .5rem;
}

.services-card p {
  color: rgb(61, 58, 58);
  text-align: center;
}

.services-card-btn {
  text-align: center;
}

.services-card-btn button {
  border: none;
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: 1rem;
  text-transform: uppercase;
}

.services-card-btn button:hover {
  background-color: rgba(17, 107, 37, 0.849);
  border: none;
  color: white;
  font-weight: 100;
}



@media screen and (max-width:940px) {

  .main-cards-header h1 {
    font-size: 1.5rem;
  }

  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 40%;
  }
}


@media screen and (max-width:700px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 80%;
  }
}







