.topbar {
    width: 100%;
    padding: .5rem 0;
    background-color: #fff;
}

.topbar-logo {
    width: 80px;
}

.topbar-logo img {
    width: 100%;
    display: block;
}

.topbar .topbar-content {
    display: flex;
    justify-content: space-around;
    color: #222;
}


.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 14px;
    text-align: left;
}

.topbar .bar-box1 {
    display: flex;
    width: auto;
    border-right: 1px solid rgba(14, 13, 13, 0.185);
    margin: auto;
}

.topbar .bar-box1 span {
    margin: auto;
    font-size: 1.5rem;
    color: #063302;
}

.topbar .bar-box2 {
    display: flex;
    width: auto;
    border-right: 1px solid rgba(14, 13, 13, 0.185);
    margin: auto;
}

.topbar .bar-box2 span {
    margin: auto;
    font-size: 1.5rem;
    color: #063302;
}


.topbar .bar-box3 {
    display: flex;
    width: auto;
    margin: auto;
}

.topbar .bar-box3 span {
    margin: auto;
    font-size: 1.5rem;
    color: #063302; 
}

.number a {
    color: #063302; 

}

@media screen and (max-width: 1100px) {
    .topbar .topbar-hide {
        display: none;
    }

    .topbar {
        width: 100%;
        padding: 1rem 0 0 0;
        background-color: #fff;
    }
}


@media screen and (max-width: 600px) {
    .topbar .topbar-content p {
        font-size: 12px;
    }

    .topbar .bar-box3 span {
        font-size: 1.2rem;
    }
    
}