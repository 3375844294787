
  .section2-header {
    text-align: center;
    padding: 3rem 1rem;
  }

  .section2-header h1 {
    color: rgba(19, 18, 18, 0.863);
    padding: .5rem;
  }

  .section2-flex {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 3rem 1rem;
  }

  .text p, .text2 p {
    color: rgb(31, 29, 29);
    font-size: 14px;
    line-height: 15px;
  }

  .text h1, .text2 h1 {
    color: var(--primary-color);
    padding: .5rem 0;
    font-size: 1.2rem;
  }

/* ------------ FLEX 1 ------------------ */

  /* LEFT */

  .section2-left-content {
    border: none;
    display: flex;
    border-top-right-radius: 7rem;
    border-bottom-right-radius: 7rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
  }

  .section2-left-content .number {
    width: 180px;
    margin: auto;
    padding: .5rem;
  }

  .section2-left-content .number img{
    width: 100%;
    height: auto;
  }

  .section2-left-content .text {
    margin: auto;
    width: 100%;
    padding: .5rem;
  }

  .section2-left-content .circle {
    margin: auto;
    width: 50%;
    padding: .5rem;
  }

  .section2-left-content .circle img {
    width: 100%;
    height: auto;
  }


/* RIGHT */
  .section2-left {
    width: 50%;
    margin: auto;
  }

  .section2-right {
    width: 50%;
    margin: auto;
  }

  .section2-right-content {
    border: none;
    display: flex;
    border-top-right-radius: 7rem;
    border-bottom-right-radius: 7rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
  }


  .section2-right-content .number {
    width: 220px;
    margin: auto;
    padding: .5rem;
  }

  .section2-right-content .number img{
    width: 100%;
    height: auto;
  }

  .section2-right-content .text {
    margin: auto;
    width: 100%;
    padding: .5rem;
  }

  .section2-right-content .circle {
    margin: auto;
    width: 50%;
    padding: .5rem;
  }

  .section2-right-content .circle img {
    width: 100%;
    height: auto;
  }


  @media  screen and (max-width: 940px) {
    .section2-flex {
      flex-wrap: wrap;
    }

    .section2-right {
      width: 90%;
    }

    .section2-left {
      width: 90%;
    }

  }



  /* ------------ FLEX 3 ------------------ */


  .section2-flex2 {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 2rem 1rem; 
  }

  
  /* LEFT */

  .section2-left-content2 {
    border: none;
    display: flex;
    border-top-left-radius: 7rem;
    border-bottom-left-radius: 7rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
  }

  .section2-left-content2 .number2 {
    width: 250px;
    margin: auto;
    padding: .5rem;
  }

  .section2-left-content2 .number2 img{
    width: 100%;
  }

  .section2-left-content2 .text2 {
    margin: auto;
    width: 100%;
    padding: .5rem;
  }

  .section2-left-content2 .circle2 {
    margin: auto;
    width: 50%;
    padding: .5rem;
  }

  .section2-left-content2 .circle2 img {
    width: 100%;
  }


  .section2-left2 {
    width: 50%;
    margin: auto;
  }

  .section2-right2 {
    width: 50%;
    margin: auto;
  }

  .section2-right-content2 {
    border: none;
    display: flex;
    border-top-left-radius: 7rem;
    border-bottom-left-radius: 7rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.100);
  }

/* RIGHT */
  .section2-right-content2 .number2 {
    width: 250px;
    margin: auto;
    padding: .5rem;
    right: -5%;
  }

  .section2-right-content2 .number2 img {
    width: 100%;
  }

  .section2-right-content2 .text2 {
    margin: auto;
    width: 100%;
    padding: .5rem;
  }

  .section2-right-content2 .circle2 {
    margin: auto;
    width: 50%;
    padding: .5rem;
  }

  .section2-right-content2 .circle2 img {
    width: 100%;
  }


  @media  screen and (max-width: 940px) {
    .section2-flex2 {
      flex-wrap: wrap;
    }

    .section2-right2 {
      width: 90%;
    }

    .section2-left2 {
      width: 90%;
    }

  }